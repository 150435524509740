<template>
	<div class="form-col">
		<div class="text-h6">Pet Name</div>
		<div class="text-caption desc">
			Your pet name. Names must be appropriate and only include alphanumeric
			characters and underscores.
		</div>
		<v-text-field
			class="pt-3"
			:rules="rules"
			v-model="name"
			label="Pet Name"
			counter="32"
			required
			dense
			outlined
		></v-text-field>
	</div>
</template>

<script>
const field = 'name';

export default {
	name: 'CustomPetFormName',
	data: () => ({
		rules: [
			(v) => !!v || 'required',
			(v) => !v || v.length <= 32 || 'Pet name must be under 32 characters',
			(v) =>
				!v || v.length >= 3 || 'Pet name must be at least 3 or more characters',
			(v) =>
				!v ||
				!!v.match(/^[a-zA-z0-9_]+$/) ||
				'Pet name can only contain alphanumeric characters and underscores',
		],
	}),
	computed: {
		name: {
			get() {
				return this.$store.getters.customPetForm[field];
			},
			set(value) {
				this.$store.dispatch('updateCustomPetForm', { field, value });
			},
		},
	},
};
</script>

<style scoped>
.form-col {
	display: flex;
	flex-direction: column;
}

.desc {
	color: rgba(255, 255, 255, 0.7);
}
</style>
