<template>
	<div class="form-col">
		<div class="text-h6">Pet Event Dates</div>
		<div class="text-caption desc">Start and end dates of the event</div>
		<div class="event-dates">
			<v-menu
				ref="startMenu"
				v-model="startMenu"
				:close-on-content-click="false"
				:return-value.sync="startDate"
				transition="scale-transition"
				offset-y
				min-width="auto"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-text-field
						v-model="startDate"
						label="Start Date"
						prepend-icon="mdi-calendar"
						dense
						outlined
						readonly
						v-bind="attrs"
						v-on="on"
					></v-text-field>
				</template>
				<v-date-picker v-model="startDate" no-title scrollable>
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="startMenu = false">
						Cancel
					</v-btn>
					<v-btn text color="primary" @click="$refs.startMenu.save(startDate)">
						OK
					</v-btn>
				</v-date-picker>
			</v-menu>
			<v-menu
				ref="endMenu"
				v-model="endMenu"
				:close-on-content-click="false"
				:return-value.sync="endDate"
				transition="scale-transition"
				offset-y
				min-width="auto"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-text-field
						v-model="endDate"
						label="End Date"
						prepend-icon="mdi-calendar"
						dense
						outlined
						readonly
						v-bind="attrs"
						v-on="on"
					></v-text-field>
				</template>
				<v-date-picker v-model="endDate" no-title scrollable>
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="endMenu = false"> Cancel </v-btn>
					<v-btn text color="primary" @click="$refs.endMenu.save(endDate)">
						OK
					</v-btn>
				</v-date-picker>
			</v-menu>
		</div>
	</div>
</template>

<script>
const startField = 'eventStart';
const endField = 'eventEnd';

export default {
	name: 'CustomPetFormEventDates',
	data: () => ({
		rules: [
			(v) => {
				if (!v) return true;
				const names = v?.split(',') || [];
				if (names.length > 5) {
					return 'You can only have 5 aliases.';
				}
				for (let i in names) {
					const name = names[i];
					if (name.length > 32) {
						return 'Pet name must be under 32 characters';
					} else if (name.length < 3) {
						return 'Pet name must be at least 3 or more characters';
					}
				}
				return true;
			},
			(v) =>
				!v ||
				!!v.match(/^[a-zA-z0-9_,]+$/) ||
				'Pet name can only contain alphanumeric characters and underscores',
		],
		startMenu: false,
		endMenu: false,
	}),
	computed: {
		startDate: {
			get() {
				const alt = this.$store.getters.customPetForm[startField] || [];
				return alt.join(',');
			},
			set(value) {
				value = value?.split(',') || [];
				this.$store.dispatch('updateCustomPetForm', {
					field: startField,
					value,
				});
			},
		},
		endDate: {
			get() {
				const alt = this.$store.getters.customPetForm[endField] || [];
				return alt.join(',');
			},
			set(value) {
				value = value?.split(',') || [];
				this.$store.dispatch('updateCustomPetForm', { field: endField, value });
			},
		},
	},
};
</script>

<style scoped>
.form-col {
	display: flex;
	flex-direction: column;
}

.desc {
	color: rgba(255, 255, 255, 0.7);
	padding-bottom: 12px;
}

.optional {
	color: rgba(255, 255, 255, 0.7);
}

.event-dates {
	display: flex;
	gap: 20px;
}
</style>
