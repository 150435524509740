<template>
	<div class="form-col">
		<div class="text-h6">
			{{ title }} <i v-if="!event" class="text-caption optional">(optional)</i>
		</div>
		<div class="text-caption desc">
			{{ desc }}
		</div>
		<div>
			<div class="user-row" v-for="user in users" :key="user.id">
				<v-btn
					color="error"
					text
					:disabled="user.noDelete"
					@click="deleteUser(user)"
				>
					<v-icon>mdi-trash-can-outline</v-icon>
				</v-btn>
				<div class="text-h7">{{ user.username }}</div>
			</div>
		</div>
		<v-btn
			v-if="users.length < 5"
			@click="showUserModal = true"
			outlined
			text
			color="primary"
		>
			+ Add User
		</v-btn>
		<v-dialog v-model="showUserModal" width="min-content" height="min-content">
			<v-card
				color="background"
				width="350"
				class="gift-card"
				:style="{ 'border-color': 'var(--v-primary-base) !important' }"
			>
				<v-card-title class="pb-0">{{ modalText }}</v-card-title>
				<v-card-text class="info-text">
					<div>Copy and paste their Discord user ID here!</div>
					<a :href="helpLink" target="_blank" class="how-text"
						>How do I get a user ID?</a
					>
					<v-text-field
						class="pt-7"
						v-model="friendId"
						clearable
						dense
						:loading="fetching"
						label="Discord ID"
						@input="typing"
						:rules="idRules"
						:error-messages="fieldErrors"
					>
					</v-text-field>
				</v-card-text>
				<v-card-actions class="pb-6 pt-0">
					<v-spacer></v-spacer>
					<v-btn color="primary" :disabled="!friend" @click="addUser">{{
						buttonText
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: 'CustomPetFormCreators',
	props: {
		gift: {
			type: Boolean,
			default: false,
		},
		event: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		helpLink:
			'https://support.discord.com/hc/en-us/articles/206346498-Where-can-I-find-my-User-Server-Message-ID-',
		friendId: null,
		friend: null,
		blurTimeout: null,
		fetching: false,
		showUserModal: false,
		rules: [
			(v) => !!v || 'required',
			(v) => !v || v.length <= 32 || 'Pet name must be under 32 characters',
			(v) => !v || v.length >= 3 || 'Pet name must be at least 2 characters',
			(v) =>
				!v ||
				v.match(/^[a-zA-z0-9_]+$/) ||
				'Pet name can only contain alphanumeric characters and underscores',
		],
		idRules: [
			(v) => !!v || 'ID Required',
			(v) => {
				const pattern = /^\d{15,20}$/;
				return pattern.test(v) || 'Invalid Discord ID';
			},
		],
		invalidId: false,
		refresh: 0,
	}),
	computed: {
		desc() {
			if (this.event) {
				return 'Add up to 5 users as artists to this pet!';
			} else if (this.gift) {
				return 'Add up to 5 users to gift this pet!';
			} else {
				return 'Add up to 5 users as creators of this pet!';
			}
		},
		modalText() {
			if (this.event) {
				return 'Adding an artist?';
			} else if (this.gift) {
				return 'Gifting a friend?';
			} else {
				return 'Adding a creator?';
			}
		},
		buttonText() {
			if (this.friend) {
				return `Add ${this.friend.username}`;
			} else {
				return 'Add...';
			}
		},
		fieldErrors() {
			if (this.invalidId) {
				return ['Could not find user with this ID'];
			} else if (this.userExists(this.friendId)) {
				return ['Id already exists'];
			}
			return [];
		},
		users() {
			this.refresh;
			let users = this.$store.getters.customPetForm[this.field] || [];
			users = [...users];
			if (!this.gift && !this.event) {
				users.unshift({
					id: this.user.id,
					username: '@' + this.user.username,
					noDelete: true,
				});
			}
			return users;
		},
		user() {
			return this.$store.getters.user;
		},
		field() {
			if (this.event) {
				return 'artists';
			} else if (this.gift) {
				return 'gifts';
			} else {
				return 'creators';
			}
		},
		title() {
			if (this.event) {
				return 'Artists';
			} else if (this.gift) {
				return 'Gift to';
			} else {
				return 'Creators';
			}
		},
	},
	methods: {
		validId(id) {
			return /^\d{15,20}$/.test(id);
		},
		typing(id) {
			this.invalidId = false;
			this.friend = null;
			this.fetching = false;
			clearTimeout(this.blurTimeout);
			if (!this.validId(id)) {
				return;
			}
			this.fetching = true;
			this.blurTimeout = setTimeout(() => {
				this.fetchUsername(id);
			}, 2000);
		},
		async fetchUsername(id) {
			this.invalidId = false;
			this.friend = null;
			if (!this.validId(id)) {
				return;
			}
			this.fetching = true;
			try {
				const user = await this.$store.dispatch('getUserById', id);
				this.friend = user;
			} catch (err) {
				this.invalidId = true;
			} finally {
				this.fetching = false;
			}
		},
		userExists(id) {
			if (this.users.find((ele) => ele.id === id)) {
				return true;
			}
		},
		addUser() {
			if (this.userExists(this.friend.id)) {
				return;
			}
			let users = this.$store.getters.customPetForm[this.field] || [];
			users.push({
				id: this.friend.id,
				username: this.friend.username,
			});
			this.$store.getters.customPetForm[this.field] = users;
			this.invalidId = false;
			this.friend = null;
			this.fetching = false;
			this.friendId = null;
			this.showUserModal = false;
			this.refresh++;
		},
		deleteUser(user) {
			let users = this.$store.getters.customPetForm[this.field] || [];
			users = users.filter((ele) => user.id !== ele.id);
			this.$store.getters.customPetForm[this.field] = users;
			this.refresh++;
		},
	},
};
</script>

<style scoped>
.form-col {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 15px;
}

.user-row {
	display: flex;
	align-items: center;
}

.gift-card {
	display: flex;
	align-items: center;
	flex-direction: column;
	border: 3px solid var(--v-primary-base);
}

.how-text {
	font-size: 0.8rem;
}

.info-text {
	text-align: center;
}

.desc {
	color: rgba(255, 255, 255, 0.7);
}

.optional {
	color: rgba(255, 255, 255, 0.7);
}
</style>
